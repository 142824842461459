import { BaseLevel } from '#auth/constant'
import type { PermissionType, UpdateUserProfilePhotoParams } from '#auth/types'

/**
 * A composable that manages permission paths and their checked states.
 * Provides methods to load permissions, set checked states,
 * check if a permission is checked,
 * and get the final list of allowed and disallowed permissions.
 */
export const usePermissionPaths = () => {
  const permissionPaths = useState<{
    [key: string]: boolean
  }>('permissionPaths', () => ({}))

  /**
   * Loads permissions into the permissionPaths state.
   *
   * @param permissions - An array of PermissionType objects.
   */
  const loadPermissions = (
    permissions: PermissionType[],
    checkDefaultLevel: string = ''
  ): boolean => {
    let allChecked = true

    permissions.forEach((permission: PermissionType) => {
      // Recursively load children first
      let allChildrenChecked = false
      if (permission.children) {
        allChildrenChecked = loadPermissions(
          permission.children,
          checkDefaultLevel
        )
      }

      let checked = false
      if (allChildrenChecked) {
        // If all children are checked, check the parent
        checked = true
      } else if (checkDefaultLevel) {
        // Check if the permission is included in the default levels
        if (permission.defaultLevels.includes(checkDefaultLevel)) {
          checked = true
        } else if (
          checkDefaultLevel === BaseLevel.ADMIN &&
          permission.defaultLevels.includes(BaseLevel.MEMBER)
        ) {
          checked = true
        }
      }

      if (!checked) {
        allChecked = false
      }

      permissionPaths.value[permission.path] = checked
    })

    return allChecked
  }

  const updateChildren = (basePath: string, state: boolean) => {
    Object.keys(permissionPaths.value).forEach((key: string) => {
      if (key.startsWith(basePath + '.')) {
        permissionPaths.value[key] = state
        updateChildren(key, state) // Recursively update children
      }
    })
  }

  const updateParent = (path: string, state: boolean) => {
    const parentPath = path.split('.').slice(0, -1).join('.')
    if (parentPath) {
      // Update the parent if all its children are in the same state
      const allChildrenInState = Object.keys(permissionPaths.value)
        .filter((key: string) => key.startsWith(parentPath + '.'))
        .every((child: string) => permissionPaths.value[child] === state)

      permissionPaths.value[parentPath] = state && allChildrenInState
      updateParent(parentPath, state) // Recursively update parent
    }
  }

  /**
   * Resets the checked state of all permission paths to false.
   */
  const resetChecked = (): void => {
    Object.keys(permissionPaths.value).forEach((key: string) => {
      permissionPaths.value[key] = false
    })
  }

  /**
   * Sets the checked state of a permission path and its children.
   *
   * @param path - The permission path to set.
   * @param checked - The checked state to set.
   */
  const setCheckedPermission = (path: string, checked: boolean): void => {
    // Set the state for the current path and its children
    permissionPaths.value[path] = checked
    updateChildren(path, checked)

    // Update parent state based on the new state of its children
    if (checked) {
      updateParent(path, true)
    } else {
      // When unchecked, propagate the unchecked state up the hierarchy
      updateParent(path, false)
    }
  }

  /**
   * Sets the checked state of multiple permission paths.
   *
   * @param paths - An array of permission paths to set.
   * @param checked - The checked state to set (default is true).
   */
  const setMultipleChecked = (
    paths: string[],
    checked: boolean = true
  ): void => {
    paths.forEach((path: string) => {
      setCheckedPermission(path, checked)
    })
  }

  /**
   * Returns an array of parent paths as strings.
   *
   * @param path - The input path string.
   * @returns An array of parent paths as strings.
   */
  const getParentPaths = (path: string): string[] => {
    const parentPaths: string[] = []
    const parentParts = path.split('.').slice(0, -1)
    let toCheckPath = ''
    for (const part of parentParts) {
      toCheckPath += part
      parentPaths.push(toCheckPath)
      toCheckPath += '.'
    }

    return parentPaths
  }

  /**
   * Checks if a permission path or any of its parents is checked.
   *
   * @param path - The permission path to check.
   * @returns A boolean indicating if the path or any parent is checked.
   */
  const isCheckedPermission = (path: string): boolean => {
    const checked = permissionPaths.value[path]
    if (checked) {
      return true
    }

    // Also check if any parent is checked
    for (const parentPath of getParentPaths(path)) {
      if (permissionPaths.value[parentPath]) {
        return true
      }
    }

    return false
  }

  /**
   * Checks if a permission path is in an indeterminate state.
   *
   * @param path - The permission path to check.
   * @returns A boolean indicating if the path is in an indeterminate state.
   */
  const isIndeterminatePermission = (path: string): boolean => {
    // If the path itself is checked, it's not indeterminate
    if (permissionPaths.value[path]) {
      return false
    }

    // Check if any child is checked
    const childrenChecked = Object.keys(permissionPaths.value)
      .filter(key => key.startsWith(path + '.'))
      .some(child => permissionPaths.value[child])

    return childrenChecked
  }

  /**
   * Gets the final list of allowed and disallowed permissions.
   *
   * @returns An object containing arrays of allowed and disallowed permissions.
   */
  const getFinalList = (): {
    allowedPermissions: string[]
    disallowedPermissions: string[]
  } => {
    const allowedPermissions: string[] = []
    const disallowedPermissions: string[] = []

    // Sort permissionPaths by key
    const sortedPermissionPaths = Object.keys(permissionPaths.value).sort()

    // Traverse all permission paths
    for (const path of sortedPermissionPaths) {
      // Skip if parent is already allowed
      let parentIncluded = false
      for (const parentPath of getParentPaths(path)) {
        if (allowedPermissions.includes(parentPath)) {
          parentIncluded = true
          break
        }
      }

      if (parentIncluded) {
        continue
      }

      if (path) {
        if (permissionPaths.value[path]) {
          allowedPermissions.push(path)
        } else {
          disallowedPermissions.push(path)
        }
      }
    }

    return {
      allowedPermissions,
      disallowedPermissions,
    }
  }

  return {
    permissionPaths,
    loadPermissions,
    setCheckedPermission,
    isCheckedPermission,
    isIndeterminatePermission,
    getFinalList,
    resetChecked,
    setMultipleChecked,
  }
}

export const useUserTimezone = () => {
  const { isLoggedIn, auth } = storeToRefs(useAuthStore())

  const autoUpdateTimezone = async () => {
    // Skip if not logged in or user has disabled auto update timezone
    if (!isLoggedIn.value || (auth.value.timezone && !auth.value.timezoneAutoUpdate)) {
      return
    }

    let updateTimezone = false

    // If timezone is not set, update it
    const currentTimezone = getDeviceTimezone()
    if (!auth.value.timezone) {
      updateTimezone = true
    } else {
      if (currentTimezone !== auth.value.timezone) {
        updateTimezone = true
      }
    }

    if (updateTimezone && currentTimezone) {
      const payload: UpdateUserProfilePhotoParams = { input: {
        timezone: currentTimezone,
        workspaceId: null,
      } }

      const { mutate, error } = useUpdateUserMutation(payload)
      await mutate()

      if (error.value) {
        handleSingleGraphQLError(error.value)
      }
    }
  }

  return {
    autoUpdateTimezone,
  }
}
